import AboutMe from "../components/AboutMe";
import AboutProject from "../components/AboutProject";

const AboutUs = () => {
    return ( 
        <div >
            <AboutProject/>
            <AboutMe/>
        </div>

     );
}
 
export default AboutUs;