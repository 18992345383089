const LodingPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
    <div className="text-center">
      <div className="spinner-border" role="status"></div>
    </div>
  </div>
  );
};

export default LodingPage;
