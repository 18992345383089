export interface StateAndDelegation{
    [state:string] :string[]
}


export const TunisiaStateAndDelegation : StateAndDelegation = {
      "تونس" :[
            "قرطاج",
            "المدينة",
            "باب البحر",
            "باب سويقة",
            "العمران",
            "العمران الأعلى",
            "التحرير",
            "المنزه",
            "حي الخضراء",
            "باردو",
            "السيجومي",
            "الزهور",
            "الحرائرية",
            "سيدي حسين",
            "الوردية",
            "الكبارية",
            "سيدي البشير",
            "جبل الجلود	",
            "حلق الوادي",
            "الكرم",
            "المرسى",
      ] ,
      "أريانة"  : [
            "أريانة المدينة",
            "سكرة",
            "رواد",
            "قلعة الأندلس",
            "سيدي ثابت",
            "حي التضامن",
            "المنيهلة",
      ],
      "منوبة": [
            "منوبة",
            "وادي الليل",
            "طبربة",
            "البطان",
            "الجديدة",
            "المرناقية",
            "برج العامري",
            "دوار هيشر",

      ],
      'بن عروس':[
            "بن عروس",
            "المدينة الجديدة",
            "المروج",
            "حمام الأنف",
            "حمام الشط",
            "بومهل البساتين",
            "الزهراء",
            "رادس",
            "مقرين",
            "المحمدية",
            "فوشانة",
            "مرناق",
      ],
      "نابل" : [
            "نابل",
            " دار شعبان الفهري",
            "بني خيار",
            "قربة",
            "منزل تميم",
            "الميدة	",
            "قليبية",
            "حمام الأغزاز",
            "الهوارية",
            "تاكلسة",
            "سليمان",
            "منزل بوزلفة",
            "بني خلاد",
            "قرمبالية",
            "قرمبالية",
            "الحمامات",
      ],
      "بنزرت": [
        "بنزرت الشمالية",
        "جرزونة",
        "بنزرت الجنوبية",
        "سجنان",
        "جومين",
        "ماطر",
        "غزالة",
        "منزل بورقيبة",
        "تينجة",
        "أوتيك",
        "غار الملح",
        "منزل جميل",
        "العالية",
        "رأس الجبل",
      ],
      "زغوان" : [
        "زغوان",
        "الزريبة",
        "بئر مشارقة",
        "الفحص",
        "الناظور",
        "صواف",
      ],
      "سوسة" :[
        "سوسة المدينة",
        "الزاوية القصيبة الثريات",
        "سوسة الرياض",
        "سوسة",
        "سوسة سيدي عبد الحميد",
        "حمام سوسة",
        "أكودة",
        "القلعة الكبرى",
        "سيدي بوعلي",
        "هرقلة",
        "النفيضة",
        "بوفيشة",
        "كندار",
        "سيدي الهاني",
        "مساكن",
        "القلعة الصغرى",
      ],
      "المنستير" :[
        "المنستيـر",
        "الوردانيـن",
        "الساحليـن",
        "زرمديـن",
        "بنـي حسان",
        "جمـال",
        "بنبلة",
        "المكنين",
        "البقالطة",
        "طبلبة",
        "قصر هلال",
        "قصيبة المديوني",
        "صيادة لمطة بوحجر",
      ],
      "المهدية" : [
        "المهدية",
        "بومرداس",
        "أولاد الشامخ",
        "شربان",
        "هبيرة",
        "السواسي",
        "الجم",
        "الشابة",
        "ملولش",
        "سيدي علوان	",
        "قصور الساف	",
      ],
      "صفاقس" : [
        "صفاقس المدينة",
        "صفاقس الغربية",
        "ساقية الزيت",
        "ساقية الداير",
        "صفاقس الجنوبية",
        "طينة",
        "عقارب",
        "جبنيانة",
        "العامرة",
        "الحنشة",
        "منزل شاكر",
        "الغريبة",
        "بئر علي بن خليفة",
        "الصخيرة",
        "المحرس",
        "قـرقنـة",
      ],
      "باجة": [
        "باجة الشمالية",
        "باجة الجنوبية",
        "عمدون",
        "نفزة",
        "تبرسق",
        "تيبار",
        "تستور",
        "قبلاط",
        "مجاز الباب",
      ],
      "جندوبة":[
        "جندوبة",
        "جنـدوبة الشمالية",
        "بوسالم",
        "طبرقـة",
        "عين دراهم",
        "فرنانة",
        "غار الدماء",
        "وادي مليز",
        "بلطة  بوعوان",
      ],
      "الكاف":[
        "الكاف الغربية",
        "الكاف الشرقية",
        "نبـر",
        "الطويرف",
        "ساقية سيدي يوسف",
        "تاجروين",
        "قلعة سنان",
        "القلعة الخصبة",
        "الجريصة",
        "القصور",
        "الدهماني",
        "السرس",
      ],
      "سليانة":[
        "سليانة الشمالية",
        "سليانة الجنوبية",
        "بوعرادة",
        "قعفور",
        "العروسة",
        "الكريب",
        "بورويس",
        "مكثر	",
        "الروحية",
        "كسرى",
        "برقو",
        
      ],
      "القيروان":[
        "القيروان الشمالية",
        "القيروان الجنوبية",
        "الشبيكة",
        "السبيخة",
        "الوسلاتية",
        "حفوز",
        "العلا",
        "حاجب العيون",
        "نصر الله",
        "الشراردة",
        "بوحجلة",
        "عين جلولة",
        "منزل المهيري", 
      ],
      "سيدي بوزيد" :[
        "سيدي بوزيد الغربية",
        "سيدي بوزيد الشرقية",
        "جلمة",
        "سبالة أولاد عسكر",
        "بئر الحفي",
        "سيدي علي بن عون",
        "منزل بوزيان	",
        "المكناسي	",
        "سوق الجديد	",
        "المزونة	",
        "الرقاب",
        "السعيدة",
        "أولاد حفوز",
       
      ],
      "القصرين"	:[
        "القصرين الشمالية",
        "القصرين الجنوبية",
        "الزهور",
        "حاسي الفريد",
        "سبيطلة",
        "سبيبة",
        "جدليان",
        "العيون",
        "تالة",
        "حيدرة",
        "فوسانة",
        "فريانة",
        "ماجل بلعباس",
      ],
      "قابس"	:[
        "قابـس المدينة",
        "قابـس الغربية",
        "قابـس الجنوبية",
        "غنوش",
        "المطوية",
        "منزل الحبيب",
        "الحامة",
        "مطماطة	",
        "مطماطة الجديدة",
        "مارث",
        "دخيلة توجان",
      ],
      "مدنين":[
        "مدنيـن الشمالية",
        "مدنين الجنوبية",
        "بني خداش",
        "بن قردان",
        "جرجيس",
        "جربة حومة السوق",
        "جربة ميدون",
        "جربة أجيم",
        "سيدي مخلوف",
      ],
      "قفصة":[
        "قفصة الشمالية",
        "سيدي عيش",
        "القصر",
        "قفصة الجنوبية",
        "أم العرائس",
        "سيدي بوبكر",
        "الرديف",
        "المتلوي",
        "المظيلة",
        "القطار",
        "بلخير",
        "السند",
        "صانوش",
      ],
      "توزر":[
        "توزر",
        "دقاش",
        "تمغزة",
        "نفطة",
        "حزوة",
        "حامة الجريد",
      ],
      "تطاوين":[
        "تطاوين الشمالية",
        "تطاوين الجنوبية",
        "الصمار",
        "البئر الأحمر",
        "غمراسن",
        "ذهيبة",
        "رمادة",
        "بني مهيرة",
      ],
      "قبلي"	:[
        "قبلي الجنوبية",
        "قبلي الشمالية",
        "سوق الأحد",
        "دوز الشمالية",
        "دوز الجنوبية",
        "الفوار",
        "رجيم معتوق",
      ]
    
}